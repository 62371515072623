import BrandingService, {Currency, Price, TarifficationPeriod} from '../BrandingService';
import footerTemplate from "../tele2/portal-footer.html";
import pageTerms from './page-terms.html';
import pageAuth from './page-auth.html';

import headerTemplate from './portal-header.html';
import {SongAction} from "../../application/karaoke/services/entity/SongAction";
import TcellAuthController from "./TcellAuthController";
// import footerTemplate from './portal-footer.html'

const opRes = require.context('../../application/karaoke/', true, /tcell\.m\.less/);

export default (application) => {

    application.run(function(module, angular) {
        module.factory('BrandingService', () => BrandingService.create("TCell")
            .withName('Мобильное Караоке')
            .withCoreActions([SongAction.PERFORM, SongAction.PERFORM_RECORD, SongAction.PERFORM_BATTLE])
            .withSubscriptionPrice(new Price(1.43, Currency.TJS, TarifficationPeriod.DAY_H24))
            .withTrafficPrice(new Price(0, Currency.TJS))
            .hasTerms(pageTerms)
            .hasBasicAuth(pageAuth)
            .build());

        module.controller("TcellAuthController", TcellAuthController);

        module.run(["$templateCache", ($templateCache) => {
            $templateCache.put('portal-header.html', headerTemplate);
            // $templateCache.put('portal-footer.html', footerTemplate);
        }]);
    }, opRes);
};
