import {karaoke} from "../../application/karaoke/karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import DataService from "../../application/karaoke/services/DataService";
import TextUtilService from "../../application/karaoke/services/TextUtilService";
import AuthService from "../../application/karaoke/services/AuthService";
import {LandingSubAuthController} from "../../application/karaoke/controllers/BasicSubAuthController";

export default class TcellAuthController extends LandingSubAuthController {

    static $inject = [
        '$scope', '$rootScope', 'DataService', 'TextUtilService', 'AuthService'
    ];

    constructor(
        protected readonly $scope,
        protected readonly $rootScope: IRootScopeService,
        protected readonly dataService: DataService,
        protected readonly textUtilService: TextUtilService,
        protected readonly authService: AuthService,
    ) {
        super("/integration/tcell", $scope, $rootScope, dataService, textUtilService, authService)
        const landingMode = this.$rootScope.mode === 'landing'

        this.$scope.checkboxAccepted = landingMode;

        if (this.$scope.subscribeMode && this.$scope.checkboxAccepted && landingMode) {
            this.doSubscribe()
        }
    }

}
