import "./PageTitleComponent.less";
import template from "./PageTitleTemplate.html";
import {Component} from "../../../general/decorators";
import {karaoke} from "../../karaoke";
import UserProfile from "../../services/entity/UserProfile";
import BrandingService from "../../../../branding/BrandingService";
import {Language} from "../../../../branding/Language";
import TextUtilService from "../../services/TextUtilService";
import SettingsService from "../../services/SettingsService";
import {SongAction} from "../../services/entity/SongAction";
import EventService from "../../services/EventService";

@Component({
    controllerAs: '$ctrl',
    bindings: {
        quickSearch: "<",
        // searchOverlayVisible: "<"
    },
    template: template
})
export default class PageTitleComponent implements ng.IOnInit {

    static $inject = ['$rootScope', '$scope', '$route', 'BrandingService', 'TextUtilService', 'EventService'];

    public user: UserProfile;
    public battleAvailable: boolean;
    public go: (path: string) => void;
    public pageControllerName: string;
    public showTerms: () => void;
    public searchOverlayVisible: false;
    public showBackButton: boolean = true;

    constructor(
        private readonly $rootScope: karaoke.IRootScopeService,
        private readonly $scope: ng.IScope,
        private readonly $route: ng.route.IRouteService,
        private readonly brandingService: BrandingService,
        private readonly textUtilService: TextUtilService,
        private readonly eventService: EventService,
    ) {
    }

    $onInit(): void {

        this.$rootScope.$watch("user", (user:UserProfile) => {
            this.user = user;
        });

        this.go = this.$rootScope.go;
        this.battleAvailable = this.brandingService.available(SongAction.PERFORM_BATTLE);
        this.showTerms = this.$rootScope.showTerms;

        const $scope = this.$scope as any;
        const $parent = $scope.$parent;

        $scope.$ctrl.playAction = $scope.$parent.playAction;
        $scope.$ctrl.toggleMenu = $scope.$parent.toggleMenu;
        $scope.$ctrl.authorize = $scope.$parent.authorize;
        $scope.$ctrl.backurl = $scope.$parent.backurl;
        $scope.$ctrl.hideSearch = $scope.$parent.hideSearch;

        $scope.$ctrl.showSearch = () => {
            if (this.brandingService.operator !== 'Tele2') {
                this.$rootScope.go('/search/');
            } else {
                $scope.$ctrl.searchOverlayVisible = true;
                $scope.$ctrl.quickSearch.value = '';
                $scope.$ctrl.quickSearch.songs = [];
            }
        };

        $scope.$ctrl.hideSearch = () => {
            $scope.$ctrl.searchOverlayVisible = false;
        };

        this.eventService.onActiveEvent((eventId) => $scope.styleEvent = `event-${eventId}`);

        // $scope.$ctrl.searchOverlayVisible = $scope.$parent.searchOverlayVisible;
        this.pageControllerName = (this.$route.current as any).$$route.controller; // todo: other way?
        this.showBackButton = (this.pageControllerName !== 'MainPageController'
            && this.pageControllerName !== 'MainPromoPageController');

        // $scope.$ctrl.quickSearch = $scope.$parent.quickSearch;
    };

    public availableLanguages(): Array<Language> {
        return this.brandingService.availableLanguages;
    }

    public lang(lang: string) {
        this.textUtilService.setLanguage(lang);
    };

    /*public rawLanguageCodesEnabled(): boolean {
        return this.settingsService.isRawLanguageCodesEnabled(this.availableLanguages());
    };*/
}
