import {BasicAuthResult} from "../services/entity/BasicAuthResult";
import BasicAuthController from "./BasicAuthController";
import DataService from "../services/DataService";
import TextUtilService from "../services/TextUtilService";
import AuthService from "../services/AuthService";
import {karaoke} from "../karaoke";
import IRootScopeService = karaoke.IRootScopeService;

/**
 * Профиль пользователя
 */
export class CommonAuthProfile {
    opNetwork: boolean
    msisdn: string
}

export enum CommonResponseStatus {
    OK = 'OK',
    ERROR = 'ERROR'
}

export class CommonOrder {
    msisdn: string
    orderId: string
    originOrderId: string
    createdAt: Date
    price: number
}

export class CommonOpenResponse {
    status: CommonResponseStatus
    order: CommonOrder
    message: string
}

export class CommonWebhookData {
    orderId: string
    originOrderId: string
    startedDatetime: Date
    finishedDatetime: Date
    serviceToken: string
    serviceName: string
    price: number
    phone: string
}

export class CommonWebhookSubscriptionData {
    webhook: CommonWebhookData
    result: BasicAuthResult
    authToken: string
}

export enum CommonLandingStage {
    AUTH = 0,
    ENTER_CODE = 1,
    AWAIT_RESULT = 2,
    SUBSCRIBED = 3,
    ERROR = 4
}

export class CommonPasswordResponse {
    /**
     * Статус запроса
     * “ok” или “error”
     */
    status: CommonResponseStatus;

    /**
     * Количество попыток переотправки пароля для данного заказа.
     */
    attemptsLeft: number

    /**
     * Текст ошибки в случае если status = “error”.
     * Если status = “ok”, данное поле будет содержать пустую строку
     */
    message: string
}

/**
 * Базовый класс для авторизаций, связанных с сетевыми запросами
 */
export abstract class NetworkAuthController extends BasicAuthController {

    /**
     * Отмечаем начало загрузки, показываем условный спиннер
     */
    protected markLoadingBegin() {
        this.$scope.loading = true;
    }

    /**
     * Отмечаем успешное окончание загрузки, скрываем условный спиннер
     */
    protected markLoadingSuccess() {
        this.$scope.error = false;
        this.$scope.loading = false;
    }

    /**
     * Отмечаем окончание загрузки с ошибкой, скрываем условный спиннер и отображаем ошибку.
     *
     * @param message текст ошибки
     */
    protected markLoadingError(message: string) {
        this.$scope.error = true;
        this.$scope.errorMessage = message;
        this.$scope.loading = false;
    }


    protected isOk(value: BasicAuthResult) : boolean {
        return value == BasicAuthResult.OK || value as any == "OK"// todo: custom deserializer
    }

}

/**
 * Контроллер, используемый в страницах page-auth
 */
export abstract class BasicSubAuthController extends NetworkAuthController {

    /**
     * Флаг, определяющий, активен ли компонент или был вызван $destroy
     */
    private lifecycleDestroy = false;

    protected constructor(
        /** Префикс интеграционного URL для запроса landing endpoint-ов */
        protected readonly urlPrefix: string,
        /** Scope страницы */
        protected readonly $scope,
        /** Сервис, позволяющий запрашивать данные подписки */
        protected readonly dataService: DataService,
        /** Сервис, локализирующий тексты */
        protected readonly textUtilService: TextUtilService,
        /** Сервис авторизации */
        protected readonly authService: AuthService,
    ) {
        super($scope, textUtilService, authService)

        $scope.msisdn = authService?.userData?.msisdn

        /** Проброс доступных статусов авторизации через ленинг в Scope */
        $scope.stage = CommonLandingStage

        $scope.error = false
        $scope.errorMessage = null

        $scope.loading = false;

        /**
         * Режим лендинга – проход по стадиям stage (CommonLandingStage), текущий статус – landingStage
         */
        $scope.landingMode = false

        $scope.doAuth = () => $scope.landingMode = false

        /**
         * Обработка кнопки «Продолжить» после сообщения о том что пользователь подписан и логин/пароль отправены по SMS
         */
        $scope.doAuthAfterSub = () => this.doAuthAfterSub($scope.subData)
        $scope.doCheck = () => this.doCheckSubscriptionStatus()
        $scope.doBegin = () => this.doBeginAuthorization();
        //$scope.doOpen = () => this.doOpenSubscriptionSession()
        $scope.doValidate = () => this.doValidate()
        $scope.doResend = () => this.doResend()
        // $scope.doSubscribeOnWebhookResult = (orderId: string, response: CommonPasswordResponse) => this.doSubscribeOnWebhookResult(orderId, response)
        // $scope.doHandleSuccessResult = (data: CommonWebhookSubscriptionData) => this.doHandleSuccessResult(data)
        $scope.$on('$destroy', () => this.lifecycleDestroy = true);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * Начало процесса авторизации.
     * Также, обработка действия «Вернуться к вводу номера» в случае состояния ошибки.
     */
    protected doBeginAuthorization = () => {
        this.$scope.msisdn = null;
        this.$scope.landingStage = CommonLandingStage.AUTH;

        this.markLoadingBegin();
        this.dataService.dataHttp<CommonAuthProfile>(`${this.urlPrefix}/landing/profile.json`).then(response => {
            this.$scope.landingMode = true; // response.data.opNetwork;
            this.markLoadingSuccess()

            if (!!response.data) { // if (!!response.data.msisdn) { // if (!this.$scope.msisdn) {
                this.doHandleProfile(response.data)
            }
        }, () => {
            // Невозможно загрузить подписку
            this.markLoadingError(this.textUtilService.localizedText("auth.error.unable-to-load-sub"))
        });
    }

    /**
     * Обработка полученного профиля пользователя с MSISDN
     */
    protected doHandleProfile(data: CommonAuthProfile) {
        this.$scope.msisdn = data.msisdn;
        this.$scope.landingStage = CommonLandingStage.AUTH;
    }

    /**
     * «Войти» по номеру телефона
     */
    protected doCheckSubscriptionStatus = () => {
        const msisdn = this.$scope.msisdn;

        console.info("doCheckSubscriptionStatus");

        if (!!msisdn) {
            let clearMsisdn = msisdn.replace(/ /g,'').replace(/\+/g,'')

            this.markLoadingBegin();
            this.dataService.dataHttp<BasicAuthResult>(`${this.urlPrefix}/subscription-status.json`, {
                params: {
                    'phone': clearMsisdn
                }
            }, 'POST').then(response => {
                if (this.isOk(response.data)) {
                    // уже подписан: response.data == BasicAuthResult.OK;
                    this.$scope.landingMode = false;
                    this.$scope.msisdn = clearMsisdn;
                    this.markLoadingSuccess();
                } else {
                    this.markLoadingSuccess();
                    this.doOpenSubscriptionSession()
                }
            }, () => {
                this.markLoadingErrorGlobal(this.textUtilService.localizedText("payment.error.general", {}, "Невозможно осуществить подписку"))
            });
        } else {
            this.markLoadingError(this.textUtilService.localizedText("auth.required-msisdn", {}, "Введите номер телефона"))
        }
    }

    /**
     * Начать процесс подписки: открыть сессию / отправить пароль
     */
    protected doOpenSubscriptionSession() {
        const msisdn = this.$scope.msisdn;
        if (!msisdn) {
            this.markLoadingError(this.textUtilService.localizedText("auth.required-msisdn", {}, "Введите номер телефона"))
            return
        }
        let clearMsisdn = msisdn.replace(/ /g, '').replace(/\+/g, '')

        this.markLoadingBegin();
        this.dataService.dataHttp<CommonOpenResponse>(`${this.urlPrefix}/landing/open.json`, {
            params: {
                'phone': clearMsisdn
            }
        }, 'POST').then(response => {
            if (response.data.status == CommonResponseStatus.OK) {
                console.info(response.data);
                console.info(response.data.order);
                this.$scope.order = response.data.order;
                this.$scope.landingStage = CommonLandingStage.ENTER_CODE;
                this.markLoadingSuccess()
            } else if (response.data.status == CommonResponseStatus.ERROR) {
                this.markLoadingError(response.data.message)
            } else {
                console.warn(`wrong answer from /landing/open: ${JSON.stringify(response.data)}`);
                // некорректный ответ от сервера
                this.markLoadingError(this.textUtilService.localizedText("auth.error.wrong-server-response"))
            }
        }, () => {
            this.markLoadingError(this.textUtilService.localizedText("payment.error.general", {}, "Невозможно осуществить подписку"))
        });
    }

    /**
     * «Продолжить» для введённого кода, полученного в SMS
     */
    protected doValidate = () => {
        const orderId = this.orderId();
        const password = this.$scope.code;
        if (!password) {
            // Введите пароль
            this.markLoadingError(this.textUtilService.localizedText("auth.label-enter-password"))
            return
        }
        if (!!orderId) {
            this.markLoadingBegin();
            this.dataService.dataHttp<CommonPasswordResponse>( `${this.urlPrefix}/landing/validate-password.json`, {
                params: {
                    'orderId': orderId,
                    'password': password
                }
            }, 'POST').then(response => {
                if (response.data.status == CommonResponseStatus.OK) {
                    this.$scope.landingStage = CommonLandingStage.ENTER_CODE;
                    this.$scope.attemptsLeft = response.data.attemptsLeft;
                    this.markLoadingSuccess()

                    // const orderId = (this.$scope.order as CommonOrder)?.orderId;
                    this.doSubscribeOnWebhookResult(orderId, response.data)
                } else if (response.data.status == CommonResponseStatus.ERROR) {
                    this.markLoadingError(response.data.message)
                } else {
                    console.warn(`wrong answer from uztelecom/landing/validate-password: ${JSON.stringify(response.data)}`);
                    // некорректный ответ от сервера
                    this.markLoadingError(this.textUtilService.localizedText("auth.error.wrong-server-response"))
                }
            }, () => {
                // Невозможно проверить пароль
                this.markLoadingError(this.textUtilService.localizedText("auth.error.unable-to-verify-password"))
            });
        } else {
            this.handleWrongOrderId()
        }
    }

    /**
     * Повторная отправка пароля по SMS
     */
    protected doResend = () => {
        const orderId = this.orderId();
        if (!!orderId) {
            this.dataService.dataHttp<CommonPasswordResponse>(`${this.urlPrefix}/landing/resend-password.json`, {
                params: {
                    'orderId': orderId,
                    'lang': this.textUtilService.currentLanguage,
                }
            }, 'POST').then(response => {
                if (response.data.status == CommonResponseStatus.OK) {
                    this.$scope.landingStage = CommonLandingStage.ENTER_CODE;
                    this.$scope.attemptsLeft = response.data.attemptsLeft;
                    this.markLoadingSuccess()
                } else if (response.data.status == CommonResponseStatus.ERROR) {
                    this.markLoadingError(response.data.message)
                } else {
                    console.warn(`wrong answer from /landing/resend-password: ${JSON.stringify(response.data)}`);
                    // некорректный ответ от сервера
                    this.markLoadingError(this.textUtilService.localizedText("auth.error.wrong-server-response"))
                }
            }, () => {
                // Невозможно повторно запросить пароль
                this.markLoadingError(this.textUtilService.localizedText("auth.error.unable-to-request-password"))
            });
        } else {
            this.handleWrongOrderId()
        }
    }

    /**
     * Обработка результата успешной проверки пароля
     *
     * @param orderId идентификатор подписки (заказа), по которой был проверен пароль
     * @param response результат валидации
     */
    protected doSubscribeOnWebhookResult = (orderId: string, response: CommonPasswordResponse) => {
        this.$scope.landingStage = CommonLandingStage.AWAIT_RESULT;
        this.subscribe(orderId, 10)
    }

    /**
     * Обработка результата подписки. Переход к состоянию SUBSCRIBED в случае успеха.
     *
     * @param data результат подписки
     */
    protected doHandleSuccessResult = (data: CommonWebhookSubscriptionData) => {
        console.info(data.result);
        if (this.isOk(data.result)) {
            this.$scope.landingStage = CommonLandingStage.SUBSCRIBED;
            this.$scope.subData = data;
            // show button with action doAuthAfterSub on state SUBSCRIBED
        } else {
            // 'Подписка не оформлена: ' + data.result
            this.markLoadingErrorGlobal(this.textUtilService.localizedText("auth.error.subscription-failed", {response: data.result, interpolation: {escapeValue: false}}))
        }
    }

    /**
     * Обработка кнопки «Продолжить» для состояния SUBSCRIBED (Вы подписаны. Логин и пароль для дальнейшего входа отправлены по SMS.)
     *
     * @param data результат подписки
     */
    protected doAuthAfterSub(data: CommonWebhookSubscriptionData) {
        this.markLoadingSuccess()

        if (!!data.authToken) {
            this.authService.reloadPage();
        }
    }

    /**
     * Отмечаем окончание загрузки с ошибкой, скрываем условный спиннер и отображаем ошибку.
     * Ошибка нефатальная, можем продолжить авторизацию по лендингу.
     *
     * @param message текст ошибки
     */
    protected markLoadingError(message: string) {
        this.$scope.attemptsLeft = null;
        super.markLoadingError(message)
    }

    /**
     * Отмечаем окончание загрузки с фатальной ошибкой, скрываем условный спиннер и отображаем ошибку.
     * Прерываем процесс авторизации для лендинга.
     *
     * @param message текст ошибки
     */
    protected markLoadingErrorGlobal(message: string) {
        this.$scope.landingStage = CommonLandingStage.ERROR;
        this.markLoadingError(message);
    }

    /**
     * Идентификатор текущего заказа (подписки)
     */
    protected orderId() {
        return (this.$scope.order as CommonOrder)?.orderId;
    }

    /**
     * Обработка случая некорректного (устаревшего) номера заказа
     */
    protected handleWrongOrderId() {
        this.$scope.landingStage = CommonLandingStage.AUTH;
        this.$scope.order = null
        this.$scope.attemptsLeft = null
        // Некорректный идентификатор запроса
        this.markLoadingError(this.textUtilService.localizedText("auth.error.wrong-request-id"))
    }

    /**
     * Реализация подписки
     *
     * @param orderId – идентификатор подписки (заказа)
     * @param retryLimit – счётчик оставшихся попыток
     */
    protected subscribe(orderId: string, retryLimit: number) {
        if (!this.isActive()) {
            // если был вызван destroy контроллера, запрос на подписку не пройдёт
            return;
        }

        if (retryLimit < 0) {
            // Превышено число попыток. Попробуйте позже.
            this.markLoadingErrorGlobal(this.textUtilService.localizedText("auth.error.attempts-exceed"))
            return;
        }

        const self = this;
        if (!!orderId) {
            // запрашиваем статус покупки, ожидая ненулевое время окончания верификации, либо код ошибки
            self.dataService.dataHttp<CommonWebhookSubscriptionData>( `${this.urlPrefix}/landing/status.json`, {
                params: {
                    'orderId': orderId,
                }
            }, 'POST').then(response => {
                // новые данные
                try {
                    console.info(response.data);
                    if (!!response.data.webhook.finishedDatetime) {
                        self.doHandleSuccessResult(response.data)
                    } else {
                        setTimeout(new function () {
                            self.subscribe(orderId, --retryLimit);
                        }, 1000); // попробовать ещё раз через 1 сек
                    }
                } catch (e) {
                    console.error(e);
                    setTimeout(new function () {
                        self.subscribe(orderId, --retryLimit);
                    }, 1000); // попробовать ещё раз через 1 сек
                }
            }, errorResponse => {
                switch (errorResponse.status) {
                    case 404:
                    case 405:
                        // Запрос на подписку не найден
                        this.markLoadingErrorGlobal(this.textUtilService.localizedText("auth.error.wrong-order-id"))
                        break
                    case 502:
                    case 503:
                        console.info("retry on " + errorResponse.status + " " + errorResponse.statusText);
                        setTimeout(new function () {
                            self.subscribe(orderId, --retryLimit);
                        }, 1000); // попробовать ещё раз через 1 сек
                        break;
                    default: // console.warn(`subscribe on webhook returns code ${errorResponse.status}`);
                        // this.markLoadingError('Невозможно получить статус подписки')
                        console.warn(`subscribe on webhook returns code ${errorResponse.status}`);
                        setTimeout(new function () {
                            self.subscribe(orderId, --retryLimit);
                        }, 1000); // попробовать ещё раз через 1 сек
                        break;
                }
            })
        } else {
            this.handleWrongOrderId()
        }
    }

    protected isOk(value: BasicAuthResult) : boolean {
        return value == BasicAuthResult.OK || value as any == "OK"// todo: custom deserializer
    }

    protected isActive() {
        return this.lifecycleDestroy === false;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export abstract class LandingSmsAuthController extends BasicSubAuthController {

    protected constructor(
        /** Префикс интеграционного URL для запроса landing endpoint-ов */
        protected readonly urlPrefix: string,

        protected readonly $scope,
        protected readonly $rootScope: IRootScopeService,
        protected readonly dataService: DataService,
        protected readonly textUtilService: TextUtilService,
        protected readonly authService: AuthService,
    ) {
        super(urlPrefix, $scope, dataService, textUtilService, authService)
        this.doBeginAuthorization();
    }

    protected doHandleProfile(data: CommonAuthProfile) {
        this.$scope.msisdn = data.msisdn;

        if (this.$rootScope.mode === 'landing') { // $rootScope
            // MobiuzAuthController, UZTelecomAuthController case
            // Все условия подписки присутствуют на странице лендинга.
            // После нажатия пользователем кнопки войти сразу происходит отправка SMS-кода
            this.doOpenSubscriptionSession();
        } else {
            this.$scope.landingStage = CommonLandingStage.AUTH;
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export abstract class LandingSubAuthController extends NetworkAuthController {

    protected constructor(
        /** Префикс интеграционного URL для запроса landing endpoint-ов */
        protected readonly urlPrefix: string,

        protected readonly $scope,
        protected readonly $rootScope: IRootScopeService,
        protected readonly dataService: DataService,
        protected readonly textUtilService: TextUtilService,
        protected readonly authService: AuthService,
    ) {
        super($scope, textUtilService, authService)

        // изменение стандартного поведения – авторизованный пользователь может быть не подписан
        this.$scope.msisdn = authService.userData.msisdn
        this.$scope.subscribeMode = authService.userData.authenticated && !authService.isSubscribedMain();
        this.$scope.doSubscribe = this.doSubscribe;
        this.$scope.showTerms = this.$rootScope.showTerms;
        this.$scope.doCancelSubscribeMode = ($event: UIEvent) => {
            $event?.preventDefault(); // ?
            $event?.stopPropagation();
            this.$scope.subscribeMode = false;
        }
    }

    /**
     * Обработка действия «Подписаться на Караоке» (для режима subscribeMode)
     */
    protected doSubscribe = () => {
        let msisdn = this.authService.userData.msisdn;
        if (!!msisdn) {
            let clearMsisdn = msisdn.replace(/ /g,'').replace(/\+/g,'')

            this.markLoadingBegin();
            // HESubscribeIntegrationController endpoint
            this.dataService.dataHttp<BasicAuthResult>(`${this.urlPrefix}/subscribe.json`, {
                params: {
                    'phone': clearMsisdn
                }
            }, 'POST').then(response => {
                // const alreadySubscribed = response.data == BasicAuthResult.OK;
                if (this.isOk(response.data)) {
                    this.markLoadingSuccess()

                    this.authService.reloadPage();
                    // this.authService.submitActionDefault(new SubMainContext());
                } else {
                    // `Некорректный ответ: ${response.data}`
                    let message = this.textUtilService.localizedText("auth.error.wrong-answer", {response: response.data, interpolation: {escapeValue: false}})
                    this.markLoadingError(message)
                }
            }, () => {
                this.markLoadingError(this.textUtilService.localizedText("payment.error.general", {}, "Невозможно осуществить подписку"))
            });
        } else {
            this.markLoadingError(this.textUtilService.localizedText("auth.required-msisdn", {}, "Введите номер телефона"))
        }
    }
}